import React, { Component } from 'react'
import { graphql } from 'gatsby'
import ls from 'local-storage'
import GatsbyLink from '../components/GatsbyLink'
import Previewable from '../components/Previewable'
import SEO from '../components/SEO'
import RelatedProducts from '../components/Posts/Related/RelatedProducts'
import { RenderShortcode } from '../components/RenderShortcode'
import RenderContent from '../components/RenderContent'
import { Image } from '../components/Image'
import AddToQuote from '../components/Quote/AddToQuote'
import { Breadcrumbs } from '../components/Breadcrumbs'
import Accordion from '../components/Accordion'
import './product.scss'
import { decodeEntities, slugify, isClient } from '../utils/helpers'
import { FaChevronRight } from '../images/icons/FaChevronRight'
import { FaChevronLeft } from '../images/icons/FaChevronLeft'
import FaTimes from '../images/icons/FaTimes'
import { GlobalTrending } from '../components/Acf/GlobalTrending'
import { NewsletterSignUp } from '../components/NewsletterSignUp'

const enableBodyScroll = () => {
  if (isClient) {
    document.body.classList.remove('nav-open')
  }
}

class ProductPostTemplate extends Component {
  state = {
    filter: 'all',
    openNavKey: null,
    activeImage: null,
  }

  componentDidMount() {
    ls.set('activeImage', null)
    if (isClient) {
      document.addEventListener('keydown', this.handleEscKey, false)
    }
  }

  toggleModal = (event, openNavKey) => {
    event.preventDefault()
    document.body.classList.remove('modal-open')
    const { openNavKey: currentOpenNavKey } = this.state
    if (currentOpenNavKey === openNavKey)
      return this.setState({ openNavKey: null })
    openNavKey && document.body.classList.add('modal-open')
    return this.setState({ openNavKey })
  }

  updateActiveImage = image => {
    if (image.source_url) {
      this.setState({ activeImage: image })
    }
  }

  handleEscKey = event => {
    const { toggleNav, active } = this.props
    if (event.keyCode === 27 && active) toggleModal(event)
  }

  render() {
    const { post, location, wordpressAcfOptions } = this.props
    const {
      wordpress_id,
      title,
      content,
      date,
      categories,
      acf,
      featured_media,
      featured_image_url,
    } = post
    const { openNavKey, activeImage } = this.state
    const firstImage =
      activeImage || featured_image_url?.source_url || featured_media
    const {
      productImageGallery,
      tabbedContent,
      colours,
      productVariations,
      material,
      dimensions,
      features,
      weight,
    } = acf
    const productOptions = {
      colours,
      variations: productVariations,
    }

    const galleryArray = []
    colours && colours.forEach(image => galleryArray.push(image.colourImage))
    productImageGallery &&
      productImageGallery.forEach(image => galleryArray.push(image))

    return (
      <section className="single-product">
        <div className="outer">
          <div className="wrap">
            <div className="inner">
              <Breadcrumbs location={location} />

              <div className="product">
                <div className="images">
                  <div className="feature-image">
                    {firstImage && (
                      <div className="image">
                        <div className="aspect">
                          <Image className="image" src={firstImage} lazyLoad />
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="modal-container">
                    {galleryArray &&
                      galleryArray.map((image, index) => (
                        <div
                          key={index}
                          className={`gallery-modal${
                            openNavKey === `key-${index}` ? ' open' : ' closed'
                          }`}
                        >
                          <div className="inner">
                            <div className="wrap">
                              <Image
                                className="image"
                                src={image}
                                lazy={false}
                              />
                            </div>
                          </div>
                          <div className="modal-controls">
                            <FaTimes
                              className="close"
                              onClick={event =>
                                this.toggleModal(event, `key-${index}`)
                              }
                            />
                            {galleryArray.length > index + 1 && (
                              <button
                                className="next"
                                onClick={event =>
                                  this.toggleModal(event, `key-${index + 1}`)
                                }
                                aria-label="Next"
                              >
                                <FaChevronRight />
                              </button>
                            )}
                            {index > 0 && (
                              <button
                                className="back"
                                onClick={event =>
                                  this.toggleModal(event, `key-${index - 1}`)
                                }
                                aria-label="Prev"
                              >
                                <FaChevronLeft />
                              </button>
                            )}
                          </div>
                        </div>
                      ))}
                  </div>

                  <div className="product-gallery-controls">
                    {galleryArray &&
                      galleryArray.map((image, index) => (
                        <div className="image" key={index}>
                          <div
                            className="aspect"
                            onClick={event =>
                              this.toggleModal(event, `key-${index}`)
                            }
                          >
                            <Image className="image" src={image} lazyLoad />
                          </div>
                        </div>
                      ))}
                  </div>
                </div>

                <div className="details">
                  <div className="pad">
                    <h1 className="product-title">{decodeEntities(title)}</h1>

                    <AddToQuote
                      productId={wordpress_id}
                      updateActiveImage={this.updateActiveImage}
                      options={productOptions}
                      settings={wordpressAcfOptions.options}
                    />

                    <div className="description">
                      <RenderShortcode content={content} />
                      {acf.productDetails && (
                        <RenderContent content={acf.productDetails} />
                      )}
                    </div>

                    <div className="features">
                      {weight && (
                        <div className="feature">
                          <span className="name weight">Weight</span>
                          <span>
                            {weight}
                            kg
                          </span>
                        </div>
                      )}
                      {material && (
                        <div className="feature">
                          <span className="name material">Material</span>
                          <span>{material}</span>
                        </div>
                      )}
                      {dimensions && (
                        <div className="feature">
                          <span className="name dimensions">Dimensions</span>
                          <span>{dimensions}</span>
                        </div>
                      )}
                      {features &&
                        features.map((feature, index) => {
                          const slugClass =
                            feature.feature.value === 'other'
                              ? slugify(feature.otherLabel)
                              : slugify(feature.feature.value)
                          const label =
                            feature.feature.value === 'other'
                              ? feature.otherLabel
                              : feature.feature.label
                          const value =
                            feature.feature.label === 'Other' ||
                            feature.feature.label === 'Origin'
                              ? feature.otherValue
                              : 'Yes'
                          return (
                            <div className="feature" key={index}>
                              <span className={`name ${slugClass}`}>
                                {label}
                              </span>
                              <span>{value}</span>
                            </div>
                          )
                        })}
                    </div>
                  </div>
                </div>
              </div>

              {tabbedContent && tabbedContent.length > 0 && (
                <div className="tabbed-content">
                  <div className="section-title">
                    <span className="h2">Additional Information</span>
                  </div>
                  <Accordion concertina={tabbedContent} />
                </div>
              )}

              {categories && categories.length ? (
                <div className="taxonomy">
                  <h4>Categories</h4>
                  <ul className="taglist">
                    {categories.map(category => (
                      <li key={`${category.slug}cat`}>
                        {category.slug && (
                          <GatsbyLink
                            to={`/products/category/${category.slug}/`}
                          >
                            {category.name}
                          </GatsbyLink>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </section>
    )
  }
}

const ProductPost = props => {
  const { data, location } = props
  const {
    wordpressPost: post,
    wordpressWpSettings,
    wordpressAcfOptions,
    siteSettings,
  } = data
  if (!post) return null
  const {
    title,
    content,
    featured_image_url,
    yoast,
    yoast_head,
    categories,
    date,
    wordpress_id,
  } = post
  const relatedPostsCheck = post.acf.relatedProducts && post.acf.relatedProducts
  const { newsletterText } = siteSettings.options

  return (
    <>
      <SEO
        title={`${yoast.metaTitle || decodeEntities(title)} | ${decodeEntities(
          wordpressWpSettings.title
        )}`}
        desc={yoast.metaDescription}
        location={location}
      />

      <ProductPostTemplate
        location={location}
        post={post}
        blogSlug="products"
        wordpressAcfOptions={wordpressAcfOptions}
      />

      <RelatedProducts
        title="You may also like"
        contentType="products"
        postFilter={relatedPostsCheck}
        action={{ url: `/products/`, title: 'View all' }}
        listView
      />

      <GlobalTrending />
      <NewsletterSignUp content={newsletterText} />
    </>
  )
}

export default Previewable(ProductPost, 'products')

export const pageQuery = graphql`
  fragment ProductFields on wordpress__wp_products {
    id
    slug
    content
    date(formatString: "MMMM DD, YYYY")
    title
  }
  query ProductPostByID($id: String!) {
    wordpressWpSettings {
      title
      wordpressUrl
      siteUrl
    }
    wordpressAcfOptions {
      options {
        showPrice
        showQuantity
        pricePrefix
        priceSuffix
        priceDescription
        showProductNote
        quote
        quotePage
      }
    }
    wordpressPost: wordpressWpProducts(id: { eq: $id }) {
      id
      title
      slug
      content
      excerpt
      wordpress_id
      date(formatString: "MMMM DD, YYYY")
      featured_image_url {
        source_url
      }
      acf {
        colours {
          colourHex
          colourImage {
            source_url
          }
          colourName
        }
        productVariations {
          variationName
        }
        features {
          feature {
            label
            value
          }
          otherValue
          otherLabel
        }
        weight
        material
        dimensions
        productImageGallery {
          source_url
        }
        tabbedContent {
          content
          otherTitle
          tabTitle {
            value
            label
          }
        }
        relatedProducts
      }
      featured_media {
        source_url
        localFile {
          childImageSharp {
            fluid(maxWidth: 670, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
      featured_image_url {
        source_url
      }
      product_category
      # yoast_head
      yoast {
        metaTitle: title
        metaDescription: metadesc
        opengraph_image {
          source_url
        }
        twitter_image {
          source_url
        }
      }
    }
    siteSettings: wordpressAcfOptions(options: {}) {
      options {
        newsletterText
      }
    }
  }
`
